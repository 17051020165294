import $ from "jquery";
import i18next from "i18next";
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import jqueryI18next from "jquery-i18next";

const lngs = {
  it: {nativeName: "IT"},
  en: {nativeName: "EN"},
};

const rerender = () => {
  // start localizing, details:
  // https://github.com/i18next/jquery-i18next#usage-of-selector-function
  $("body").localize();
  $(`#change-lang-${i18next.language}`).addClass("active");
};

$(function () {
  // use plugins and options as needed, for options, detail see
  // https://www.i18next.com
  i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(i18nextBrowserLanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
      {
        debug: true,
        fallbackLng: "it",
        resources: {
          en: {
            translation: {
              text1:
                'Peak Consulting is a dinamic company made of an international team of professionals specialized in the "food and beverage" sector and related equipment in the foodservice / horeca retail and vending channels.',
              text2:
                "Located in Venice, Peak Consulting through its capillary network of specialists all over the world, it's an effective and fast solution for all companies interested in developing new business opportunities in international markets.",
              text3: "For any further info please  send your enquires to:",
              text4: "Contact Us",
              text5: "Headquarter",
            },
          },
          it: {
            translation: {
              text1:
              "Peak Consulting è una giovane azienda composta da un team internazionale di professionisti specializzata in “food and beverage” e relative attrezzature nei canali foodservice/horeca retail e vending.",
              text2:
              "Situata a Venezia, Peak Consulting attraverso la sua rete capillare di collaboratori in tutto il mondo rappresenta una soluzione efficace e rapida per tutte le aziende interessate a sviluppare nuove opportunità di business nei mercati internazionali.",
              text3: "Scrivici senza impegno per maggiori informazioni. ",
              text4: "Contattaci",
              text5: "DOVE SIAMO",
            },
          },
        },
      },
      (err, t) => {
        if (err) return console.error(err);

        // for options see
        // https://github.com/i18next/jquery-i18next#initialize-the-plugin
        jqueryI18next.init(i18next, $, {useOptionsAttr: true});

        // fill language switcher
        //Lang Italiano
        $("#col-select").append(
          `<a id="change-lang-it" href="#" data-toggle="tab" data-lng="${lngs.it.nativeName}">${
            lngs.it.nativeName
          }</a>`
        );
        //divider between languages
        $("#col-select").append(
          `<a id="change-lang-slash" href="#">/</a>`
        );
        //lang English
        $("#col-select").append(
          `<a id="change-lang-en" href="#" data-toggle="tab" data-lng="${lngs.en.nativeName}">${lngs.en.nativeName}</a>`
        );

        $(`#change-lang-${i18next.language}`).addClass("active");

        $("#col-select").on("click", "a", (e) => {
          e.preventDefault();
          const lng = $(e.target).attr("data-lng");
          if (lng === "IT") {
            i18next.changeLanguage("it", (err, t) => {
              $("#col-select a").removeClass("active");
              rerender();
            });
          } else {
            i18next.changeLanguage("en", (err, t) => {
              $("#col-select a").removeClass("active");
              rerender();
            });
          }
        });
        rerender();
      }
    );
});
